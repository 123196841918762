body {
    margin: 0px;
    position: relative;

    * {
        box-sizing: border-box;
        font-family: 'Titillium Web', sans-serif; 
    }

    h1 {
        margin-top: 0px;
    }

    section{
        .content{
            padding:0 32px;
            margin: 0 auto;
            width: 100%;
            max-width: 1400px;
        }
    }

    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeSpeed;

    .grecaptcha-logo,.grecaptcha-badge { visibility: hidden; }

    .mat-radio-label{
        margin: 0 4px;
    }

    .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
        border-color:#de6503;
    }


    .mat-radio-button.mat-accent .mat-radio-inner-circle{
        background-color:#de6503;
    }

    .mat-radio-button.mat-accent .mat-radio-inner-circle, 
    .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), 
    .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, 
    .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
        background-color:#de6503;
    }

    .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
    .mat-checkbox-checked.mat-accent .mat-checkbox-background {
        background-color:#de6503;
    }

    .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, 
    .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
        background: #de6503;
    }

}

html {
    scroll-behavior: smooth;
}